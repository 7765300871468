import ChatRepository from "../../../repositories/ChatRepository";
import DateUtility from "../../../utils/date-utility";
import UseAuth from "../../auth/Auth";
import ListChatModel from "../model/ListChatModel";
import Cookies from "js-cookie";
import {ws} from "../socket/Socket";
import {
    setBodyChat,
    setButtonLoading,
    setData,
    setError,
    setImage,
    setIsUploadOpen,
    setLoaderListChat,
    setModalImage,
    setRowsTextarea,
    setSearchedData,
    setSelectedItem
} from "./Reducer";
import EncryptDecrypt from "../../../services/encrypt-decrypt";

const parseMessage = (message) => {
    const type = message.type;
    if (type !== "TEXT") {
        return message;
    }

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.content.split(urlRegex).map((part, index) => {
        if (urlRegex.test(part)) {
            return (
                <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                    {part}
                </a>
            );
        }
        return part;
    });
}

const getCurrentTime = () => {
    const now = new Date();
    return now.getHours().toString().padStart(2, '0') + ":" + now.getMinutes().toString().padStart(2, '0');
};

const getType = (mediaType) => {
    if (mediaType === "video/mp4") return "VIDEO";
    if (["image/png", "image/jpeg", "image/jpg"].includes(mediaType)) return "IMAGE";
    return "TEXT";
};

const formatTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    // Reset jam, menit, detik untuk perbandingan hanya pada tanggal
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const inputDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const diffInTime = today.getTime() - inputDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24)); // selisih dalam hari

    if (diffInDays === 0) {
        // Hari ini, tampilkan jam
        return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
    } else if (diffInDays === 1) {
        // Kemarin, tampilkan 'yesterday'
        return 'Yesterday';
    } else {
        // Lebih dari 1 hari lalu, tampilkan tanggal
        return date.toLocaleDateString('en-ID', {month: 'short', day: 'numeric', year: 'numeric'});
    }
};

const checkDate = (dateString) => {
    const inputDate = new Date(dateString);
    const today = new Date();

    // Atur waktu semua tanggal ke tengah malam untuk perbandingan yang lebih akurat
    today.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    const timeDiff = (today - inputDate) / (1000 * 60 * 60 * 24); // Selisih hari

    if (timeDiff === 0) {
        return "Today";
    } else if (timeDiff === 1) {
        return "Yesterday";
    } else {
        return DateUtility.formatDate(dateString);
    }
}

const removeSecond = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
}

const truncateString = (str) => {
    const maxLength = 40;

    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
    }
    return str;
};

const manipulateChatList = (response) => {
    return response.map((item) => {
        return new ListChatModel({
            id: item.id,
            name: item.customerName,
            avatar: null,
            message: truncateString(item.lastMessage),
            time: formatTime(item.lastMessageDateTime), // Format waktu menggunakan fungsi formatTime
            unreadCount: item.totalUnreadMessage,
            type: item.lastMessageType
        });
    });
};

const getList = async (dispatch) => {
    dispatch(setLoaderListChat(true));
    let response = await ChatRepository.getListData();

    const manipulatedData = manipulateChatList(response);

    if (manipulatedData && Array.isArray(manipulatedData)) {
        dispatch(setData(manipulatedData));
        dispatch(setSearchedData(manipulatedData))
    }

    dispatch(setLoaderListChat(false));
};

const handleBodyChat = (dispatch, e) => {
    const {value} = e.target;
    dispatch(setBodyChat(value));
};

const handleSelectedChat = async (dispatch, {chat, chatList}) => {
    const chatId = chat.id;
    const response = await ChatRepository.getDetailChat({id: chatId});

    if (!response.error) {
        const user = UseAuth.getUser();
        const userId = user ? user.userId : null;

        const mappedData = {
            id: response.id,
            customerName: response.customerName,
            chat: response.chatDates
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map(chatDate => ({
                    date: checkDate(chatDate.date),
                    messages: chatDate.messages.map(message => ({
                        messageId: message.id,
                        type: message.type,
                        content: message.content,
                        time: removeSecond(message.time),
                        isRead: message.isRead,
                        isSender: message.senderId === userId
                    }))
                }))
        };

        dispatch(setSelectedItem(mappedData));
        const chatItem = JSON.stringify(chat);
        const encryptedChatItem = EncryptDecrypt.enc(chatItem);
        Cookies.set("selectedChatItem", encryptedChatItem);

        // looping ulang get list untuk menghilangkan unreadcount
        const newChatList = JSON.parse(JSON.stringify(chatList));
        const updatedChatList = newChatList.map((item) => {
            if (item.id === chatId) {
                return {
                    ...item,
                    unreadCount: 0
                };
            }
            return item;
        });
        dispatch(setData(updatedChatList));
    }
};

const uploadMedia = (dispatch, {showMedia}) => {
    dispatch(setIsUploadOpen(showMedia));
};

const changeModalImage = async (dispatch, {showModal, title, img}) => {
    dispatch(setModalImage(showModal));
    dispatch(setImage({title: title, src: img}));
};

const sendMessage = async (dispatch, {selectedItem, content}) => {
    if (content === "") return
    // hard copy supaya tidak menggangu data sebelumnya
    const item = JSON.parse(JSON.stringify(selectedItem));

    // proses kirim pesan ke server
    const payload = {
        "event": "SEND_MESSAGE_FROM_MERCHANT_TO_CUSTOMER",
        "data": {
            "chatId": item.id,
            "messageType": "TEXT",
            "messageContent": content
        }
    };
    ws.send(JSON.stringify(payload));

    // siapkan payload untuk manipulasi data chat
    const newMessage = {
        "messageId": item.id,
        "type": "TEXT",
        "content": content,
        "time": getCurrentTime(),
        "isRead": true,
        "isSender": true
    };

    // proses untuk memasukkan pesan baru ke dalam chat
    let isTodayFound = false;
    item.chat.forEach(chat => {
        if (chat.date === "Today") {
            chat.messages.push(newMessage);
            isTodayFound = true; // tanda bahwa sudah ada pesan untuk hari ini
        }
    });

    // jika belum ada chat untuk hari ini, buat baru
    if (!isTodayFound) {
        item.chat.push({
            "date": "Today",
            "messages": [newMessage]
        });
    }

    // Update Redux store dengan item yang baru
    dispatch(setSelectedItem(item));
    dispatch(setBodyChat(""));
    dispatch(setRowsTextarea(1));
};

const getDetailChat = async (dispatch, {chatId}) => {
    const response = await ChatRepository.getDetailChat({id: chatId});

    if (!response.error) {
        const user = UseAuth.getUser();
        const userId = user ? user.userId : null;

        const mappedData = {
            id: response.id,
            customerName: response.customerName,
            chat: response.chatDates
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map(chatDate => ({
                    date: checkDate(chatDate.date),
                    messages: chatDate.messages.map(message => ({
                        messageId: message.id,
                        type: message.type,
                        content: message.content,
                        time: removeSecond(message.time),
                        isRead: message.isRead,
                        isSender: message.senderId === userId
                    }))
                }))
        };

        dispatch(setSelectedItem(mappedData));
        await remappingChatListWithReadChat(dispatch, {chatId});
    }
};

const remappingChatListWithReadChat = async (dispatch, {chatId}) => {
    let response = await ChatRepository.getListData();

    const manipulatedData = manipulateChatList(response);

    // looping ulang get list untuk menghilangkan unreadcount
    const newChatList = JSON.parse(JSON.stringify(manipulatedData));
    const updatedChatList = newChatList.map((item) => {
        if (item.id === chatId) {
            return {
                ...item,
                unreadCount: 0
            };
        }
        return item;
    });
    dispatch(setData(updatedChatList));
};

const remappingChatList = async (dispatch) => {
    let response = await ChatRepository.getListData();

    const manipulatedData = manipulateChatList(response);

    if (manipulatedData && Array.isArray(manipulatedData)) {
        dispatch(setData(manipulatedData));
    }
};

const uploadMediaToAws = async (dispatch, {media, selectedItem}) => {
    dispatch(setButtonLoading(true));
    const chatId = selectedItem ? selectedItem.id : null;
    const mediaType = media ? media.type : null;
    const formData = new FormData();
    formData.append("file", media);
    const response = await ChatRepository.uploadMediaToAws(formData);
    const mediaUrl = response.url;
    const mediaPresignedUrl = response.presignedUrl;


    // kirim ke server
    const payload = {
        "event": "SEND_MESSAGE_FROM_MERCHANT_TO_CUSTOMER",
        "data": {
            "chatId": chatId,
            "messageType": getType(mediaType),
            "messageContent": mediaUrl
        }
    };
    ws.send(JSON.stringify(payload));
    dispatch(setIsUploadOpen(false));

    // hard copy
    const item = JSON.parse(JSON.stringify(selectedItem));

    // siapkan payload untuk manipulasi data chat
    const newMessage = {
        "messageId": chatId,
        "type": getType(mediaType),
        "content": mediaPresignedUrl,
        "time": getCurrentTime(),
        "isRead": true,
        "isSender": true
    };

    // proses untuk memasukkan pesan baru ke dalam chat
    let isTodayFound = false;
    item.chat.forEach(chat => {
        if (chat.date === "Today") {
            chat.messages.push(newMessage);
            isTodayFound = true; // tanda bahwa sudah ada pesan untuk hari ini
        }
    });

    // jika belum ada chat untuk hari ini, buat baru
    if (!isTodayFound) {
        item.chat.push({
            "date": "Today",
            "messages": [newMessage]
        });
    }

    // Update Redux store dengan item yang baru
    dispatch(setSelectedItem(item));
    dispatch(setBodyChat(""));
    dispatch(setButtonLoading(false));
};

const searchItemListChat = async (dispatch, {value, chatList, searchedData}) => {
    if (value === "") {
        return dispatch(setData(searchedData));
        // return await remappingChatList(dispatch);
    }

    const filteredChatList = searchedData.filter(chat => chat.name.toLowerCase().includes(value.toLowerCase()));

    dispatch(setData(filteredChatList));
};

const handleChangeTextMessage = (dispatch, e) => {
    const maxRows = 3; // Jumlah baris maksimal
    const textareaLineHeight = 20; // Tinggi setiap baris dalam pixel
    const maxChars = 250; // Batas maksimal karakter
    const phoneRegex = /\d{10,13}/; // Regex untuk mendeteksi nomor telepon (10-13 digit)

    let value = e.target.value; // Ambil value dari textarea

    value = value.trimStart();
    // Batasi jumlah karakter hingga 250
    if (value.length > maxChars) {
        dispatch(setError({isError: true, type: "LENGTH"}));
    } else if (phoneRegex.test(value)) { // Validasi nomor telepon dalam pesan
        console.log("Nomor telepon terdeteksi: ", value.match(phoneRegex)[0]);
        dispatch(setError({isError: true, type: "PHONE_NUMBER"}));
    } else {
        dispatch(setError({isError: false, type: ""}));
    }

    // Hitung jumlah baris yang ada saat ini berdasarkan tinggi scroll
    e.target.rows = 1; // Reset rows ke 1 untuk menghitung ulang tinggi
    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    // Atur jumlah rows berdasarkan input pengguna
    if (currentRows >= maxRows) {
        e.target.rows = maxRows; // Batasi rows ke maxRows
    } else {
        e.target.rows = currentRows; // Setel rows sesuai baris yang dihitung
    }

    // Simpan state jumlah rows yang baru
    dispatch(setRowsTextarea(currentRows < maxRows ? currentRows : maxRows));
    dispatch(setBodyChat(value)); // Simpan nilai chat ke state global
};

const handleKeyDownTextMessage = (dispatch, {e, selectedItem, bodyChat, rows, error}) => {
    const maxRows = 3; // Jumlah baris maksimal
    if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault(); // Cegah behavior default Enter yang menambah baris
        if (!error) {
            sendMessage(dispatch, {selectedItem, content: bodyChat}); // Panggil aksi kirim pesan
        }
    } else if (e.key === 'Enter' && e.shiftKey) {
        // Tambahkan baris baru jika Enter + Shift ditekan
        const newRows = rows + 1 <= maxRows ? rows + 1 : maxRows;
        dispatch(setRowsTextarea(newRows));
    }
};

const Action = {
    getList,
    handleSelectedChat,
    uploadMedia,
    handleBodyChat,
    changeModalImage,
    sendMessage,
    getDetailChat,
    remappingChatList,
    remappingChatListWithReadChat,
    uploadMediaToAws,
    searchItemListChat,
    handleChangeTextMessage,
    handleKeyDownTextMessage
};

export default Action;