import {useTranslation} from "react-multi-lang";
import {useDispatch, useSelector} from "react-redux";
import ButtonGreen from "../../../components/ButtonGreen/ButtonGreen";
import ButtonWhite from "../../../components/ButtonWhite/ButtonWhite";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Enum from "./Enum";
import {setModalDeclined, setModalResolve} from "../redux/Reducer";
import Action from "../redux/Action";
import ResolveModal from "./ResolveModal";

const Detail = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const detailComplaint = useSelector(state => state.reducer.detailComplaint);
    const modalDeclined = useSelector(state => state.reducer.modalDeclined);
    const modalResolve = useSelector(state => state.reducer.modalResolve);

    return (
        <>
            <div className="font-medium text-sm">
                <div className="flex flex-row items-center pb-4">
                    <h4 className="flex-1 opacity-60 font-bold">
                        DETAILS
                    </h4>
                </div>

                <div className="h-72 overflow-y-auto max-w-xs">
                    <div className="flex flex-row items-right pb-2">
                        <div className="flex-1">
                            <p className="text-green-quaternary">
                                {t("complaints.category")}
                            </p>
                            <p className="font-bold text-md">{detailComplaint.category}</p>
                        </div>
                        <div className="text-right">
                            <div
                                className="min-w-fit py-1 px-4 text-center text-green-primary font-bold rounded-xl border-2 border-green-secondary border-opacity-40">
                                {Enum.status(t, detailComplaint.status)}
                            </div>
                        </div>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("complaints.topic")}
                        </p>
                        <p className="font-bold text-md">
                            {detailComplaint.topicName}
                        </p>
                    </div>
                    
                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("complaints.complaint_id")}
                        </p>
                        <p className="font-bold text-md">
                            {detailComplaint.complaintId}
                        </p>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("complaints.customer_name")}
                        </p>
                        <p className="font-bold text-md">
                            {detailComplaint.customerName}
                        </p>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("complaints.email")}
                        </p>
                        <p className="font-bold text-md">
                            {detailComplaint.customerEmail}
                        </p>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("complaints.remarks")}
                        </p>
                        <p className="font-bold text-md">
                            {detailComplaint.remarks}
                        </p>
                    </div>

                    <hr/>

                    <h4 className="flex-1 text-green-primary font-bold my-2 text-sm">
                        {t("complaints.product_details").toUpperCase()}
                    </h4>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("complaints.invoice_no")}
                        </p>
                        <p className="font-bold text-md underline text-gold-secondary">
                            {detailComplaint?.orderDetail?.noInvoice}
                        </p>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("complaints.purchase_date")}
                        </p>
                        <p className="font-bold text-md">
                            {detailComplaint?.orderDetail?.purchaseDate}
                        </p>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("complaints.products")}
                        </p>
                        {detailComplaint?.orderDetail?.product.map((p, i) => (
                            <p className="font-bold text-md mb-2" key={i}>
                                {p.productName}
                            </p>
                        ))}
                    </div>
                </div>

                {detailComplaint.status === 0 ? (
                    <div className="flex gap-x-2">
                        <ButtonWhite
                            title={t("complaints.declined")}
                            onClick={() => {
                                dispatch(setModalDeclined(true));
                            }}
                        />
                        <ButtonGreen
                            title={t("complaints.resolve")}
                            onClick={() => {
                                dispatch(setModalResolve(true));
                            }}
                        />
                    </div>
                ) : null}

            </div>

            {modalResolve ? (
                <ResolveModal
                    onCancel={() => {
                        dispatch(setModalResolve(false));
                    }}
                    onSubmit={() => {
                        Action.handelResolveSubmit(dispatch, {
                            id: detailComplaint.id
                        });
                    }}/>
            ) : null}

            {modalDeclined ? (
                <ConfirmationModal
                    title={t("complaints.title_declined")}
                    subtitle={t("complaints.subtitle_declined")}
                    onClick={({remark}) => {
                        Action.handleDeclinedSubmit(dispatch, {
                            id: detailComplaint.id,
                            remarks: remark,
                        });
                    }}
                    onCancel={() => {
                        dispatch(setModalDeclined(false));
                    }}
                />
            ) : null}
        </>
    );
};

export default Detail;
